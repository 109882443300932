<template>
  <div class="mt-8">
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="headline pa-2"
        style="border-bottom:4px solid #d84315"
      >
        <b>Neue Berechnung "Hunde-Krankenversicherung" starten</b>
        <span class="hidden-xs-only"
          >&nbsp;|&nbsp; inkl. AntragAnnahmecheck
        </span></v-col
      >
    </v-row>
    <div style="max-width: 888px">
      <v-row class="my-4">
        <v-col cols="12" md="3">Wie heisst Ihr Hund?</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <v-text-field
            v-model="formRechner.name"
            label="Name Ihres Hundes"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row class="my-4">
        <v-col cols="12" md="3">Ihre Postleitzahl</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <v-text-field
            v-model="formRechner.plz"
            width="100px"
            label="Postleitzahl"
          />
        </v-col>
      </v-row>
      <v-row class="my-4">
        <v-col cols="12" md="3">Geschlecht Ihres Hundes?</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <v-radio-group v-model="formRechner.geschlecht">
            <v-radio value="1" label="Männlich" />
            <v-radio value="2" label="Weiblich" />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="my-4">
        <v-col cols="12" md="3">Ist Ihr Hund ein Mischling?</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <v-radio-group v-model="formRechner.mischling">
            <v-radio value="0" label="Nein, er ist reinrassig" />
            <v-radio value="1" label="Ja, er ist Mischling" /> </v-radio-group
        ></v-col>
      </v-row>
      <v-row class="my-4">
        <v-col cols="12" md="3">Hunderasse?</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <vue-simple-suggest
            v-if="rassen && (!weissnicht || formRechner.mischling == 0)"
            v-model="chosen"
            :list="rassen"
            :filter-by-query="true"
          >
            <!-- Filter by input text to only show the matching results -->
          </vue-simple-suggest>
          <div
            class="my-4"
            v-if="
              chosen == '' &&
                ((rassen && !weissnicht) || formRechner.mischling == 0)
            "
          >
            {{ chosen }}
            <v-chip @click="setChosen(701)"
              >Labrador
              <b v-if="chosenrasse == 701" class="green--text">
                &nbsp;| ausgewählt</b
              ></v-chip
            >
            <v-chip @click="setChosen(439)" class="ml-2 my-2"
              >Französische Bulldogge
              <b v-if="chosenrasse == 439" class="green--text">
                &nbsp;| ausgewählt</b
              ></v-chip
            >
            <v-chip @click="setChosen(294)" class="ml-2 my-2"
              >Chihuahua
              <b v-if="chosenrasse == 294" class="green--text">
                &nbsp;| ausgewählt</b
              ></v-chip
            >
            <v-chip @click="setChosen(82)" class="ml-2 my-2"
              >Australian Shepherd
              <b v-if="chosenrasse == 82" class="green--text">
                &nbsp;| ausgewählt</b
              ></v-chip
            >
            <v-chip @click="setChosen(602)" class="ml-2 my-2"
              >Jack Russell Terrier
              <b v-if="chosenrasse == 602" class="green--text">
                &nbsp;| ausgewählt</b
              ></v-chip
            >
            <v-chip @click="setChosen(751)" class="ml-2 my-2"
              >Malteser
              <b v-if="chosenrasse == 751" class="green--text">
                &nbsp;| ausgewählt</b
              ></v-chip
            >

            <v-chip @click="setChosen(1263)" class="ml-2 my-2"
              >Yorkshire Terrier
              <b v-if="chosenrasse == 1263" class="green--text">
                &nbsp;| ausgewählt</b
              ></v-chip
            >
          </div>
          <v-checkbox
            v-if="formRechner.mischling == 1"
            v-model="weissnicht"
            value="1"
            label="Ich kenne die Rasse nicht"
          />
        </v-col>
      </v-row>

      <v-row class="my-4" v-if="formRechner.mischling == 1 && weissnicht != 1">
        <v-col cols="12" md="3">2. Hunderasse?</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <vue-simple-suggest
            v-if="rassen && !weissnicht2"
            v-model="chosen2"
            :list="rassen"
            :filter-by-query="true"
          >
          </vue-simple-suggest>
          <v-checkbox
            v-model="weissnicht2"
            value="1"
            label="Ich kenne die 2.  Rasse nicht"
          />
        </v-col>
      </v-row>

      <div v-if="weissnicht == 1 || formRechner.mischling == 1">
        <v-row class="my-4">
          <v-col cols="12" md="3">Wie groß ist Ihr Hund?</v-col>
          <v-col cols="12" md="9" class="pt-0">
            <v-radio-group v-model="formRechner.hoehe">
              <v-radio value="1" label="0 - 44 cm" />
              <v-radio value="2" label="45 cm - 120 cm" /> </v-radio-group
          ></v-col>
        </v-row>
      </div>

      <v-row class="my-4">
        <v-col cols="12" md="3">Geburtstag Ihres Hundes</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <input-geburtstag-select
            v-on:update:year="yearChanged"
            v-on:update:month="monthChanged"
            v-on:update:day="dayChanged"
            :year.sync="jahr"
            :month.sync="monat"
            :day.sync="tag"
        /></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihr Hund aktuell gesund?</v-col>
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.ber_annahme_krank">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="2" label="Ja"/></v-col>
              <v-col cols="12" md="6"
                ><v-radio :value="1" label="Nein" />
              </v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6"
          >Wurde Ihr Hund in den letzten 6 Monaten operiert?</v-col
        >
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.ber_annahme_op6m">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"
                ><v-radio :value="2" label="Nein" />
              </v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Wird Ihr Hund zur Jagd benutzt?</v-col>
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.jagd">
            <v-row>
              <v-col cols="12" md="6"><v-radio value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"><v-radio value="0" label="Nein"/></v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihr Hund kastriert?</v-col>
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.kastration">
            <v-row>
              <v-col cols="12" md="6"><v-radio value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"><v-radio value="0" label="Nein"/></v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihr Hund gechiped?</v-col>
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.chip">
            <v-row>
              <v-col cols="12" md="6"><v-radio value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"><v-radio value="0" label="Nein"/></v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihr Hund geimpft?</v-col>
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.impfung">
            <v-row>
              <v-col cols="12" md="6"><v-radio value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"><v-radio value="0" label="Nein"/></v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Soll Homöopathie mitversichert werden?</v-col>
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.homoe">
            <v-row>
              <v-col cols="12" md="6"><v-radio value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"><v-radio value="0" label="Nein"/></v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4" v-if="showfehler">
        <v-col cols="12" md="3"><b class="red--text">Achtung</b></v-col>
        <v-col cols="12" md="9">{{ fehlerarray }}</v-col>
      </v-row>

      <v-btn
        class="nocaps mr-4 white--text"
        color="#d84315"
        depressed
        tile
        style="font-size: 24px;width:100%;height: 48px;"
        @click="getBerechnungErgebnis()"
        >hier klicken und eigenen Vergleich starten</v-btn
      ><br /><br />
    </div>
  </div>
</template>

<script>
  import VueSimpleSuggest from "vue-simple-suggest";
  import "vue-simple-suggest/dist/styles.css";

  import InputGeburtstagSelect from "./InputGeburtstagSelect";
  import DateHelper from "./../../DateHelper.js";
  export default {
    name: "index-rechner",
    components: {
      VueSimpleSuggest,

      InputGeburtstagSelect,
      DateHelper,
    },

    data: function() {
      return {
        showfehler: false,
        dateChanged: 0,
        formok: false,
        fehlerarray: false,
        tarife: false,
        rassen: false,
        fullrassen: false,
        weissnicht: false,
        weissnicht2: false,
        chosen: "",
        chosen2: "",
        chosenrasse: 0,
        tag: new Date().getDate(),
        monat: new Date().getMonth() + 1,
        jahr: new Date().getYear() + 1900,
        token: false,
        //   items: ["Affenpinscher", "Afghan Hound"],
        formRechner: {
          alter: 0,
          rasse: 0,
          type: "hunde",
          kastration: "0",
          tierart: 1,
          mischling: "0",
          plz: "",
          rasse2: 0,
          hoehe: 0,
          chip: "1",
          impfung: "1",
          jagd: "0",
          name: "",
          geburtstag: "",
          homoe: "0",
          geschlecht: "1",
          ber_annahme_op6m: 2,
          ber_annahme_krank: 2,
        },
      };
    },
    created() {
      this.getRassen();
      this.setTitle();
    },

    methods: {
      setTitle() {
        this.$log.debug("title");
        document.title = "Hunde-Krankenversicherungen vergleichen";
        document.dispatchEvent(new Event("x-app-rendered"));
      },
      dayChanged(day) {
        // this.$log.debug(`day changed: ${day}`)
        this.tag = day;
        this.calcAge();
      },
      monthChanged(month) {
        this.monat = month;
        this.calcAge();
      },
      yearChanged(year) {
        this.jahr = year;
        this.calcAge();
      },
      calcAge() {
        let age = DateHelper.calcAge(this.tag, this.monat, this.jahr);
        if (age < 0) {
          age = 0;
        }
        this.dateChanged = this.dateChanged + 1;
        this.$log.debug("calcAge", this.dateChanged);
        this.formRechner.alter = age;
        this.formRechner.geburtstag =
          this.jahr + "-" + this.monat + "-" + this.tag;
      },

      simpleSuggestionList() {
        if (this.rassen) {
          return this.rasssen;
        }
        //
        // return this.items;
        //return ["Vue.js", "React.js", "Angular.js"];
      },
      getRassen() {
        this.$store
          .dispatch("getRassen")
          .then((resp) => {
            if (resp.data.rassen) {
              this.rassen = resp.data.rassen;
              this.fullrassen = resp.data.fullrassen;
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      searchStringInArray2() {
        for (var j = 0; j < this.rassen.length; j++) {
          if (this.fullrassen[j].ras_name.match(this.chosen2))
            return this.fullrassen[j].ras_id;
        }
        return 0;
      },
      searchStringInArray() {
        for (var j = 0; j < this.rassen.length; j++) {
          if (this.fullrassen[j].ras_name.match(this.chosen))
            return this.fullrassen[j].ras_id;
        }
        return 0;
      },
      testSearch() {
        let id;
        id = this.searchStringInArray();
        this.$log.debug("string", id);
      },
      checkFormFehler() {
        if (this.formRechner.plz == "" || this.formRechner.plz.length < 3) {
          this.formok = false;
          this.showfehler = true;
          this.fehlerarray =
            "Bitte geben Sie Ihre PLZ ein. Einige Anbieter kalkulieren den Preis auch anhand der PLZ.";
          return;
        }
        if (this.dateChanged == 1) {
          this.formok = false;
          this.showfehler = true;
          this.fehlerarray =
            "Bitte geben Sie den Geburtstag Ihres Tieres ein (geschätzt)";
          return;
        }

        if (this.formRechner.mischling == 1) {
          if (this.formRechner.hoehe == 0) {
            this.formok = false;
            this.showfehler = true;
            this.fehlerarray = "Bitte geben Sie die Höhe Ihres Mischlings ein";
            return;
          }
        }
        if (this.formRechner.mischling == 0 && this.formRechner.rasse == 0) {
          if (this.formRechner.hoehe == 0) {
            this.formok = false;
            this.showfehler = true;
            this.fehlerarray = "Bitte geben Sie die Rasse Ihre Hundes ein";
            return;
          }
        }
        this.formok = true;
      },
      setChosen(id) {
        this.chosenrasse = id;
        this.$log.debug("chosen", this.chosen);
      },
      getBerechnungErgebnis() {
        if (this.chosenrasse > 0) {
          this.formRechner.rasse = this.chosenrasse;
          if (this.chosen) {
            this.formRechner.rasse = this.searchStringInArray();
          }
          if (this.chosen2 && !this.weissnicht2) {
            this.formRechner.rasse2 = this.searchStringInArray2();
          }
        } else {
          this.$log.debug("chosen 2 start", this.chosen);
          if (this.chosen && !this.weissnicht) {
            this.$log.debug("chosen 2", this.chosen);
            this.formRechner.rasse = this.searchStringInArray();
          }
          if (this.chosen2 && !this.weissnicht2) {
            this.formRechner.rasse2 = this.searchStringInArray2();
          }
        }
        this.checkFormFehler();
        if (this.formok) {
          this.$store.commit("start_loading_full");
          this.$store
            .dispatch("getBerechnungErgebnis", this.formRechner)
            .then((resp) => {
              if (resp.data.token) {
                this.$store.commit("stop_loading_full");
                this.$router.push({
                  name: "vergleich",
                  params: {
                    token: resp.data.token,
                  },
                });
              }
            })
            .catch((err) => {
              this.$log.error(err);
            });
        }
      },
    },
  };
</script>
